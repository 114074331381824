.Login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #F4F4F5;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.social-login-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.social-login-buttons button {
    border: none;
    box-shadow: none;
    outline: none;
    background: none;
    width: 20rem;
}

.social-login-buttons img {
    transition: all 0.2s ease-in-out;
}

.social-login-buttons img:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.social-login-buttons button img {
    width: 100%;
    margin: 0;
}