.editor {
    font-family: 'Inter';
    font-size: 20px;
    margin: 15px;
    padding: 5px;
    border-radius: 15px;
    transition: box-shadow 0.2s ease-in-out;
}

.active {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.section-title {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0px 0px 15px;
    border: none;
}

.section-title:focus {
    outline: none;
}

.quill {
    font-family: 'Inter';
    font-size: 16px;
}

.ql-font-inter {
    font-family: 'Inter', sans-serif;
}

.bottom-bar {
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.section-settings {
    display: flex;
    gap: 3px;
    margin-right: 15px;
    height: 50px;
}

.section-settings:hover {
    cursor: pointer;
}

.section-settings :nth-child(1) {
    transition: all 0.2s ease-in-out;
}

.section-settings :nth-child(2) {
    transition: all 0.27s ease-in-out;
}

.section-settings :nth-child(3) {
    transition: all 0.35s ease-in-out;
}

.section-settings:hover :nth-child(1), .section-settings:hover :nth-child(2), .section-settings:hover :nth-child(3) {
    transform: scale(1.25);
}


.dot {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 100%;
    display: inline-block;
    box-sizing: border-box;
}