.main-editor {
    padding: 0px 35px 0px 0px;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.main-editor .content {
    padding-top: 35px;
    margin-left: 25vw;
    margin-bottom: 100px;
    width: 75%;
    height: 100%;
    /* border: 1px solid #f5f5f5; */
    /* border-radius: 15px; */
    /* padding: 15px; */
    /* margin: 15px; */
    overflow-y: scroll;

}

.title {
    font-size: 40px;
    font-weight: 700;
    margin: 15px;
    margin-left: 30px;
    border: none;
}

.title:focus {
    outline: none;
}

.add-section {
    max-width: 20%;
    margin-left: 30px;
}

.button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 20px;
}

.button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}