.context-menu {
    position: fixed;
    height: 75vh;
    width: 18vw;
    right: 30px;
    background-color: white;
    border: 2px solid #f5f5f5;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: 35px 0px 10px 25px;
    padding: 5px 20px 20px 20px;
}

.context-menu-relative {
    height: 75vh;
    width: 30vw;
    padding: 5px;
}

.context-menu-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.context-menu-row path {
    transition: all 0.2s ease-in-out;
}

.delete:hover {
    cursor: pointer;
}

.delete:hover path {
    stroke: rgba(248, 8, 8, 1);
}

h2 {
    font-size: 20px;
    font-weight: 600;
}