.main {
    background-color: #F4F4F5;
    min-height: 100vh;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 100px;
}

.profile-photo {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
}

.profile-card {
    opacity: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    right: 85px;
    top: 51px;
    width: 300px;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
    padding: 15px;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.profile-card .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.profile-card .signout {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 800;
    color: #ff4d4d;
    cursor: pointer;
}

.profile-card.enabled {
    opacity: 1;
    width: 300px;
    pointer-events: all;
}

.profile-card h2 {
    font-size: 18px;
    font-weight: 800;
    margin: 0;
}

.profile-card p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.searchBar {
  display: flex;
  justify-content: center;
}

.searchBar input {
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 600;
  width: 400px;
  height: 25px;
  border: none;
  border-radius: 25px;
}

.searchBar input:focus {
  outline: none;
}

.recent {
    padding: 0px 80px;
}

.recent > h2 {
    color: #6e6e6e;
    font-weight: 800;
}

.recent > .documents {
  display: flex;
  flex-direction: row;
  padding-left: 0;  
}