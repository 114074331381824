.documentCard {
  background: white;
  border-radius: 20px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
  transition: 0.3s;
  height: 300px;
  width: 200px;
  margin-right: 25px;
  cursor: pointer;
}

.documentCard.new {
    background: #f5f5f5;
    border: 2px dashed #ccc;
    box-shadow: none;
    height: 300px;
    width: 200px;
    margin-right: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.documentCard.new > h3 {
    color: #ccc;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    transition: all 0.2s ease-in-out;
}

.documentCard:hover {
  transform: scale(1.03);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.documentCard.new:hover {
    transform: none;
    box-shadow: none;
    border: 2px dashed black;
}

.documentCard.new:hover > h3 {
    color: black;
}

.documentCardContent {
  padding: 10px;
  position: relative;
  min-height: 280px;
}

.documentCardBlurText {
  color: transparent;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  height: 235px;
  padding: 5px;
}

.documentCardTitle {
  position: absolute;
  bottom: 20px;
  width: 170px;
  font-size: 15px;
  font-weight: bold;
  color: black;
  margin-left: 2px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottomBar {
  position: absolute;
  bottom: 0px;
  left: 13px;
  width: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.documentCardDate {
  font-size: 14px;
  color: grey;
}

.documentCardDots {
  display: flex;
  gap: 3px; /* space between dots */
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 100%;
  display: inline-block;
  box-sizing: border-box;
}
