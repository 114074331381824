.document-options {
    height: 100vh;
    width: 20vw;
    position: fixed;
    /* background-color: rgba(250, 250, 250, 1); */
    /* gradient */
    background-image: linear-gradient(rgb(255, 255, 255), rgb(250, 250, 250), rgb(250, 250, 250), rgb(237, 237, 237));
    margin-right: 20px;
    padding: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    /* border-radius: 5px 5px 0px 0px; */
}

.document-options > h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
    color: rgba(0, 0, 0, 0.5);
}

.document-options .sections {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.document-options .section > h3 {
    font-size: 18px;
    font-weight: 700;
    border: none;
    margin: 0px;
}

.document-options .section > h3 > span {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
    width: 30px;
    text-align: center;
}